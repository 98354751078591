.pagination {
	font-family: $fontFamilyAlt;
	@include font(metaBigger);
	margin-bottom: $gridRow;
	&__items {
		display: flex;
	}
	&__item {
		display: flex;
		text-align: center;
		border-collapse: collapse;
		border: solid 1px #aeaeae; // todo parameterize in colors?
		margin-right: -1px;
		position: relative;
		z-index: 0;
		color: #c7c7c7; // todo parameterize in colors?
		@include onHover() {
			color: $colorHighlight;
			border-color: $colorHighlight;
			[data-type="svg"] {
				stroke: $colorHighlight;
			}
			z-index: 1;
		}
		&--current {
			pointer-events: none;
			color: $colorHighlight;
			border-color: $colorHighlight;
			z-index: 1;
		}
		&--next {
			[data-type="svg"] {
				transform: scaleX(-1);
			}
		}
	}

	&__itemContent {
		@include app-link;
		line-height: $gridUnit * 4;
		height: $gridUnit * 4;
		width: $gridUnit * 4;
	}

	&__item--prev &__itemContent, &__item--next &__itemContent {
		width: $gridUnit * 8;
		[data-type="svg"] {
			stroke: #c7c7c7;
		}
	}
}
