.googleMap {
	height: 100%;
	position: relative;
	width: 100%;
	z-index: 1;


	&__popupContent {
		@include font(googleMapPopup);
		color: $colorBase;
		font-family: $fontFamilyMain;
	}
}
