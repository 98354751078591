.mediaModal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	background-color: $colorBgBase;
	.js-coveredByMedia & {
		display: block;
	}
	&__content {
		height: 100%;
	}

}
