@font-face {
	font-family: 'RobotoMonoBold';
	src: url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-700.eot');
	src: url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-700.eot?#iefix')
	format('embedded-opentype'),
	url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-700.woff2') format('woff2'),
	url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-700.woff') format('woff'),
	url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-700.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'RobotoMonoRegular';
	src: url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-300.eot');
	src: url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-300.eot?#iefix')
	format('embedded-opentype'),
	url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-300.woff2') format('woff2'),
	url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-300.woff') format('woff'),
	url('../fonts/roboto-mono/roboto-mono-v6-latin-ext_latin-300.ttf') format('truetype');
	font-display: swap;
}


// scala – god knows where the funky paths come from – todo shared family name?
@font-face {
	font-family: 'Scala W02 Regular';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/scala/5649212/6150575a-384d-456b-9eb6-3afb8dabd0ca.eot#iefix');
	src: url('../fonts/scala/5649212/6150575a-384d-456b-9eb6-3afb8dabd0ca.eot#iefix') format('embedded-opentype'),
	url('../fonts/scala/5649212/6dba8e72-456a-4a75-a0c3-1fc9e81100a0.woff2') format('woff2'),
	url('../fonts/scala//5649212/75c13e6d-f107-458b-9a29-2c0535b862f1.woff') format('woff'),
	url('../fonts/scala//5649212/16844d6e-1416-4e07-96b6-1d1664346816.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Scala W02 Italic';
	font-style: italic;
	font-weight: normal;
	src: url('../fonts/scala/5649215/015c9a70-0128-4760-8e28-20a36370f4a9.eot?#iefix');
	src: url('../fonts/scala/5649215/015c9a70-0128-4760-8e28-20a36370f4a9.eot?#iefix') format('embedded-opentype'),
	url('../fonts/scala/5649215/1ee8b4dc-dc6c-44b5-8868-dc6583eb38ca.woff2') format('woff2'),
	url('../fonts/scala/5649215/f44ed5e8-227f-46bd-8c07-c4c9a98ace51.woff') format('woff'),
	url('../fonts/scala/5649215/097936f8-668d-47f1-b82d-fdd0af2bfc95.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Scala W02 Bold';
	font-style: normal;
	font-weight: bold;
	src: url('../fonts/scala/5649217/dfaba3e5-625e-4db9-9054-1ea6b2228e35.eot?#iefix');
	src: url('../fonts/scala/5649217/dfaba3e5-625e-4db9-9054-1ea6b2228e35.eot?#iefix') format('embedded-opentype'),
	url('../fonts/scala/5649217/fac859e2-419a-45b3-a3ab-83b4a264d062.woff2') format('woff2'),
	url('../fonts/scala/5649217/0de20af3-2b5f-4cd2-ba7d-f44e50e0c2df.woff') format('woff'),
	url('../fonts/scala/5649217/7cb699ea-3f45-40d0-87a6-123cee6f94b2.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Scala W02 Bold Italic';
	font-style: italic;
	font-weight: bold;
	src: url('../fonts/scala/5649219/19369ba5-6af7-4d0e-a7be-b2c6d311774d.eot?#iefix');
	src: url('../fonts/scala/5649219/19369ba5-6af7-4d0e-a7be-b2c6d311774d.eot?#iefix') format('embedded-opentype'),
	url('../fonts/scala/5649219/22cb2143-4017-44e7-9327-03cf51c6e64e.woff2') format('woff2'),
	url('../fonts/scala/5649219/ee0813f2-b0cf-421c-bc4e-742305633faf.woff') format('woff'),
	url('../fonts/scala/5649219/92f0a1a4-7647-4ac6-bc9d-49a0eed47734.ttf') format('truetype');
	font-display: swap;
}
