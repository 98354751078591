$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$baseHalf: $base / 2;

$videoRatio: 16 / 9;

$mobilePadding: pxToRem(25px);

$containerPaddingSmall: $base2;
// $containerPaddingMedium: $base5;
// $containerPaddingLarge: $base10;
$containerMaxWidth: 1920px;

$blockVerticalSpacing: $base4;
$firstBlockTopSpacing: $base8;
$teaserMinHeight: 80vh;
$teaserMinHeightIeFix: 79vh; // 1 unit less than the previous

$gutter: $base2;
$layoutGutter: $gutter;

$galleryJustifiedItemMinHeight: $base * 30;
$galleryColumnsNumber: 2;
$galleryColumnsImageRatio: 3 / 2;

$slideshowMaxItems: 20;

$slideMinHeight: 60vh;
$slideRatio: 16 / 9;
$slideGap: $gutter;


$inputPadding: $base * 0.65;
$inputNestedPadding: $inputPadding - 0.5;
$labelNestedPadding: $base * 0.4;
$inputToLabelVerticalMargin: $baseHalf;
$inputBorderSize: 1px;
$buttonBorderSize: $inputBorderSize;
$buttonMinWidth: $base * 16;
$radioOuterSize: $base2;
$radioInnerSize: $base * 1.2;
$radioInnerScale: 0.5;
$boxBorderSize: 2px;
$buttonSize: pxToRem(14) * 4;
$formFieldFullColumns: 8; // number of columns, 12 = full width

$mapHeightSmall: 50 * $base;
$mapHeightLarge: 50 * $base;

$hrSize: 1px;
$textMaxWidth: $base * 100;


$sizeVariants: (
);
