.panelLink {
	text-decoration: none;
	position: fixed;
	font-family: $fontFamilyAlt;
	@include font(metaBase);
	right: $gridUnit;
	top: $gridUnit;
	background-color: rgba(0, 0, 0, 0.3);
	padding: 0.5rem 1rem;
	color: white;
	z-index: 999999;
}
