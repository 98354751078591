.footer {
	$borderColor: transparentize($colorAlternative, 0.7);
	color: $colorAlternative;
	background-color: $colorBgAlternative;
	text-align: center;
	padding: $gridRow $mobilePadding;
	@include media($midBreakpoint) {
		padding: $gridRow * 2 $mobilePadding $gridRow $mobilePadding;
	}

	&__logo {
		margin-bottom: $gridUnit * 2;
		@include media($midBreakpoint) {
			margin-bottom: $gridRow * 2;
		}
	}

	&__logoImg {
		min-height: $gridRow * 2;
		max-width: $gridRow * 2;
	}

	&__tagLine {
		@include media($midBreakpoint) {
			width: gridColumns(3);
			max-width: gridColumns(3);
			margin: 0 auto $gridRow * 2 auto;
		}
	}

	&__tagLineLink {
		color: inherit;
		text-decoration: none;
	}

	&__menus {
		display: flex;
		flex-direction: column;
		font-family: $fontFamilyAlt;
		@include font(metaBigger);
		width: fit-content;
		margin: 0 auto;

		&:before {
			content: '';
			display: block;
			width: 1px;
			margin: $gridUnit auto;
			height: $gridRow * 2;
			background-color: $borderColor;
		}
		@include media($midBreakpoint) {
			flex-direction: row;
			width: gridColumns(6);
			max-width: gridColumns(6);
			border-top: 1px solid $borderColor;
			&:before {
				display: none;
			}
		}

	}

	&__menu {
		text-align: center;
		padding: $gridUnit;
		border-top: 1px solid $borderColor;
		&:first-child {
			border-top: none;
		}
		@include media($midBreakpoint) {
			border-top: none;
			text-align: left;
			width: gridColumns(1, 2);
			max-width: gridColumns(1, 2);
			border-right: 1px solid $borderColor;
			&:last-child {
				border-right: none;
			}
		}
	}

	&.js-covered {
		@include app-covered();
		z-index: -1;
	}

	//&.js-coveredByCollage {
	//	@include app-covered();
	//	z-index: -1;
	//}

}
