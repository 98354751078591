.imageHeader {
	margin-bottom: $gridRow;

	&__headline {
		text-align: left;
		margin-bottom: $gridRow;
		left: $mobilePadding;
		font-size: pxToRem(28px);
		color: $colorBgAlternative;
		line-height: 1;
		z-index: 1;
		font-weight: normal;
		margin-left: $mobilePadding;
		margin-right: $mobilePadding;

		@include media($midBreakpoint) {
			text-align: center;
			margin: 0 auto;
			font-size: pxToRem(56px);
			width: gridColumns(9, 11);
		}
	}

	&__image {
		margin-top: $gridRow;
	}
}
