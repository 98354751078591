.menu {
	@include media($sidebarBreakpoint) {
		position: sticky;
		top: $gridUnit;
	}

	&__link {
		@include app-link;
	}

	&__linkLabel {
		.menu__link--level2 & {
			margin-left: $base2;
		}
	}

	&__items {
		display: block;

		.menu__item--level1 & {
			display: none;
		}

		.menu__item--level1.js-active & {
			display: block;
		}

		.menu__link--level1.js-current + & {
			display: block;
		}
	}

	.mainMenu & {

		&__item {
			display: block;
			position: relative;
			align-items: center;
			//min-height: $gridRow;
			padding-top: $base2;
			padding-bottom: $base2;

			border-top: 1px solid transparentize($colorBase, 0.8);

			&--level2 {
				border-top: none;
				padding-top: $base;
				padding-bottom: $base;

				&:first-of-type {
					padding-top: $base2;
				}
			}
		}

		&__link {
			width: 100%;
			display: block;
			position: relative;
			align-items: center;

			&.js-current {
				&::before {
					position: absolute;
					display: block;
					@include app-dot($colorHighlight);
					left: 0 - ($gridUnit * 2);
					@include media($midBreakpoint) {
						left: 0 - ($gridUnit * 3);
					}
				}
			}

			&--neverActive.js-current {
				&::before {
					display: none;
					visibility: hidden;
				}
			}
		}
	}
}
