// dev only
.designGrid {
	user-select: none;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: gridColumns($numCols);
	flex-direction: column;
	z-index: 999999;
	opacity: 0.2;
	max-width: $maxContentWidth;
	margin: 0 auto; // from the given width, center the content
	&--visible {
		display: flex;
	}

	&__row {
		display: flex;
		height: $gridRow;
		border-bottom: 1px dotted green;
	}

	&__cell {
		width: 100%;
		border-left: 1px dotted green;

		&:last-child {
			border-right: 1px dotted green;
		}
	}

	&.js-covered {
		@include app-covered;
	}
}
